import { useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { forEach, isEmpty, setWith, includes } from 'lodash'

import {
  Anchor,
  Button,
  Chip,
  Grid,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'

import EnterpriseIcon, {
  CancelCircleIcon,
  ExportIcon,
  RefreshIcon,
  SaveIcon,
} from '@enterprise-ui/icons'

import {
  FormAutocomplete,
  FormDateTimePicker,
  FormTextField,
  NovaTable,
} from '@dlm/common'

import LoadDetails from './LoadDetails'

import {
  convertToLocationZone,
  formatDateTime,
  formatDate,
} from '../../../common/util/dateUtil'
import { startCase } from '../../../common/util/stringUtil'
import {
  formatDeadheadReasonCodes,
  formatDefectCategories,
  formatDefectReason,
  formatHoldReasonCodes,
} from '../../../common/util/eventConfigUtil'
import { getAlertStatusColor } from '../util/alertStatusUtil'
import { LOAD_TRACKING } from '../constants/reportConstants'
import TCCCommentsControl from './TCCCommentsControl'
import { findMultiPickRuleValues } from '../util/rulesUtil'
import { autoCompleteValidationRule } from '../util/validationUtil'
import userPreferenceService from '../services/userPreferenceService'

const columnDefs = [
  {
    headerName: 'Load ID',
    field: 'load_id',
    sortBy: 'load_id',
    width: 130,
    isRowHeader: true,
    sortable: true,
    // defaultSort: 'dsc',
  },
  {
    headerName: 'Trip ID',
    field: 'trip_id',
    sortBy: 'trip_id',
    width: 100,
    sortable: true,
  },
  {
    headerName: 'Route ID',
    field: 'route_id',
    sortBy: 'route_id',
    width: 120,
    sortable: true,
  },
  {
    headerName: 'Pro #',
    field: 'pro_number',
    sortBy: 'pro_number',
    width: 125,
    sortable: true,
  },
  {
    headerName: 'Trailer #',
    field: 'trailer_number',
    sortBy: null,
    width: 120,
    sortable: false,
  },
  {
    headerName: 'Status',
    field: 'load_status',
    sortBy: 'status',
    width: 150,
    sortable: true,
  },
  {
    headerName: 'Alert Status',
    field: 'alert_status',
    sortBy: 'alert_status',
    width: 250,
    sortable: true,
  },
  {
    headerName: 'SCAC',
    field: 'current_scac',
    sortBy: 'current_scac',
    width: 100,
    sortable: true,
  },
  {
    headerName: 'Unload Type',
    field: 'delivery_type',
    sortBy: 'delivery_type',
    width: 150,
    sortable: true,
  },
  {
    headerName: 'Origin',
    field: 'origin',
    sortBy: 'origin_id',
    width: 280,
    sortable: true,
  },
  {
    headerName: 'Destination',
    field: 'destination',
    sortBy: 'destination_id',
    width: 280,
    sortable: true,
  },
  {
    headerName: 'Expected Pickup',
    width: 170,
    field: 'expected_pickup',
    sortBy: 'expected_pickup_date',
    sortable: true,
  },
  {
    headerName: 'Expected Delivery',
    width: 170,
    field: 'expected_delivery',
    sortBy: 'expected_delivery_date',
    sortable: true,
  },
  {
    headerName: 'Delivery Sequence',
    field: 'delivery_sequence',
    sortBy: 'delivery_sequence',
    width: 180,
    sortable: true,
  },
  {
    headerName: 'Estimated Pickup',
    width: 250,
    field: 'estimated_pickup_time',
    sortBy: null,
    sortable: false,
  },
  {
    headerName: 'Actual Pickup',
    width: 250,
    field: 'actual_pickup_time',
    sortBy: null,
    sortable: false,
  },
  {
    headerName: 'Estimated Delivery',
    width: 250,
    field: 'estimated_delivery_time',
    sortBy: null,
    sortable: false,
  },
  {
    headerName: 'Actual Delivery',
    width: 250,
    field: 'actual_delivery_time',
    sortBy: null,
    sortable: false,
  },
  {
    headerName: 'Pickup Defect Reason',
    field: 'pickup_defect_reason',
    sortBy: null,
    width: 250,
    sortable: false,
  },
  {
    headerName: 'Delivery Defect Reason',
    field: 'delivery_defect_reason',
    sortBy: null,
    width: 250,
    sortable: false,
  },
  {
    headerName: 'Defect Category',
    field: 'defect_category',
    sortBy: null,
    width: 250,
    sortable: false,
  },
  {
    headerName: 'Deadhead Reason Code',
    field: 'deadhead_reason_code',
    sortBy: null,
    width: 250,
    sortable: false,
  },
  {
    headerName: 'Hold Reason Code',
    field: 'hold_reason_code',
    sortBy: null,
    width: 250,
    sortable: false,
  },
  {
    headerName: 'Carrier Comments',
    width: 300,
    field: 'carrier_comments',
    sortBy: null,
    sortable: false,
  },
  {
    headerName: 'TCC Comments',
    width: 300,
    field: 'tcc_comments',
    sortBy: null,
    sortable: false,
  },
  {
    headerName: 'Service ID',
    field: 'service_id',
    sortBy: null,
    width: 125,
    sortable: false,
  },
  {
    headerName: 'Category',
    width: 350,
    field: 'category',
    sortBy: null,
    sortable: false,
  },
  {
    headerName: 'Last Updated',
    field: 'update_date',
    sortBy: 'update_dt',
    width: 200,
    sortable: true,
  },
  {
    headerName: 'Updated By',
    field: 'update_by',
    sortBy: 'update_by',
    width: 300,
    sortable: true,
  },
]

const mapDefaultValues = (loads) => {
  const defaultValues = {}
  loads.forEach((load) => {
    const loadId = load.load_id
    defaultValues[`estimated_pickup_time-${loadId}`] =
      formatDateTime(
        load.carrier_estimated_pickup_time,
        load.origin.time_zone_offset_from_utc,
      ) || ''
    defaultValues[`actual_pickup_time-${loadId}`] =
      formatDateTime(
        load.actual_pickup_time,
        load.origin.time_zone_offset_from_utc,
      ) || ''
    defaultValues[`estimated_delivery_time-${loadId}`] =
      formatDateTime(
        load.carrier_estimated_delivery_time,
        load.destination.time_zone_offset_from_utc,
      ) || ''
    defaultValues[`actual_delivery_time-${loadId}`] =
      formatDateTime(
        load.actual_delivery_time,
        load.destination.time_zone_offset_from_utc,
      ) || ''
    defaultValues[`pickup_defect_reason-${loadId}`] = {
      id: load.pickup_defect_reason || '',
      label: load.pickup_defect_reason || '',
      value: load.pickup_defect_reason || '',
    }
    defaultValues[`delivery_defect_reason-${loadId}`] = {
      id: load.delivery_defect_reason || '',
      label: load.delivery_defect_reason || '',
      value: load.delivery_defect_reason || '',
    }
    defaultValues[`defect_category-${loadId}`] = {
      id: load.defect_category || '',
      label: load.defect_category || '',
      value: load.defect_category || '',
    }
    defaultValues[`deadhead_reason_code-${loadId}`] = {
      id: load.deadhead_reason_code || '',
      label: load.deadhead_reason_code || '',
      value: load.deadhead_reason_code || '',
    }
    defaultValues[`hold_reason_code-${loadId}`] = {
      id: load.hold_reason_code || '',
      label: load.hold_reason_code || '',
      value: load.hold_reason_code || '',
    }
    defaultValues[`carrier_comments-${loadId}`] = load.carrier_comments || ''
    defaultValues[`tcc_comments-${loadId}`] = load.tcc_comments || ''
  })
  return defaultValues
}

const mapLoads = (loads, eventConfig, rules, formContext, openLoadDetails) => {
  const mappedLoads = []
  const stopLevelUpdateCategories = findMultiPickRuleValues(rules)
  loads.forEach((load) => {
    const loadId = load.load_id
    const disableUpdates = includes(stopLevelUpdateCategories, load.subcategory)

    mappedLoads.push({
      id: load.load_id,
      load_id: {
        cellValue: load.load_id,
        cellDisplay: (
          <Anchor
            aria-label={`Load Details for ${load.load_id}`}
            as="span"
            href="#"
            onClick={() => openLoadDetails(load)}
          >
            {load.load_id}
          </Anchor>
        ),
      },
      trip_id: load.trip_id,
      route_id: load.route_id,
      pro_number: load.pro_number,
      trailer_number: load.trailer_number,
      load_status: {
        cellValue: load.load_status,
        cellDisplay: startCase(load.load_status),
      },
      alert_status: {
        cellValue: load.alert_status,
        cellDisplay: (
          <>
            {load.alert_status && (
              <Chip size="dense" color={getAlertStatusColor(load.alert_status)}>
                {load.alert_status}
              </Chip>
            )}
          </>
        ),
      },
      current_scac: load.carrier?.scac,
      delivery_type: load.delivery_type,
      origin: {
        cellValue: load.origin.name,
        cellDisplay: (
          <>
            {load.origin.id} - {startCase(load.origin.name)}
          </>
        ),
      },
      destination: {
        cellValue: load.destination.name,
        cellDisplay: (
          <>
            {load.destination.id} - {startCase(load.destination.name)}
          </>
        ),
      },
      expected_pickup: convertToLocationZone(
        load.expected_pickup_date,
        load.origin.time_zone_offset_from_utc,
      ),
      expected_delivery: convertToLocationZone(
        load.expected_delivery_date,
        load.destination.time_zone_offset_from_utc,
      ),
      delivery_sequence: load.delivery_sequence,
      estimated_pickup_time: {
        cellValue: load.carrier_estimated_pickup_time,
        cellDisplay: (
          <FormDateTimePicker
            formContext={formContext}
            type={'datetime-local'}
            name={`estimated_pickup_time-${loadId}`}
            data-testid={`estimated_pickup_time-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      actual_pickup_time: {
        cellValue: load.actual_pickup_time,
        cellDisplay: (
          <FormDateTimePicker
            formContext={formContext}
            type={'datetime-local'}
            name={`actual_pickup_time-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      estimated_delivery_time: {
        cellValue: load.carrier_estimated_delivery_time,
        cellDisplay: (
          <FormDateTimePicker
            formContext={formContext}
            type={'datetime-local'}
            name={`estimated_delivery_time-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      actual_delivery_time: {
        cellValue: load.actual_delivery_time,
        cellDisplay: (
          <FormDateTimePicker
            formContext={formContext}
            type={'datetime-local'}
            name={`actual_delivery_time-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      pickup_defect_reason: {
        cellValue: load.pickup_defect_reason,
        cellDisplay: (
          <FormAutocomplete
            formContext={formContext}
            options={formatDefectReason(eventConfig)}
            optionHeight="expanded"
            rules={
              !disableUpdates &&
              autoCompleteValidationRule(formatDefectReason(eventConfig))
            }
            name={`pickup_defect_reason-${loadId}`}
            data-testid={`pickup_defect_reason-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      delivery_defect_reason: {
        cellValue: load.delivery_defect_reason,
        cellDisplay: (
          <FormAutocomplete
            formContext={formContext}
            options={formatDefectReason(eventConfig)}
            optionHeight="expanded"
            rules={
              !disableUpdates &&
              autoCompleteValidationRule(formatDefectReason(eventConfig))
            }
            name={`delivery_defect_reason-${loadId}`}
            data-testid={`delivery_defect_reason-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      defect_category: {
        cellValue: load.defect_category,
        cellDisplay: (
          <FormAutocomplete
            formContext={formContext}
            options={formatDefectCategories(eventConfig)}
            optionHeight="expanded"
            rules={
              !disableUpdates &&
              autoCompleteValidationRule(formatDefectCategories(eventConfig))
            }
            name={`defect_category-${loadId}`}
            data-testid={`defect_category-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      deadhead_reason_code: {
        cellValue: load.deadhead_reason_code,
        cellDisplay: (
          <FormAutocomplete
            formContext={formContext}
            options={formatDeadheadReasonCodes(eventConfig)}
            optionHeight="expanded"
            rules={
              !disableUpdates &&
              autoCompleteValidationRule(formatDeadheadReasonCodes(eventConfig))
            }
            name={`deadhead_reason_code-${loadId}`}
            data-testid={`deadhead_reason_code-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      hold_reason_code: {
        cellValue: load.hold_reason_code,
        cellDisplay: (
          <FormAutocomplete
            formContext={formContext}
            options={formatHoldReasonCodes(eventConfig)}
            rules={
              !disableUpdates &&
              autoCompleteValidationRule(formatHoldReasonCodes(eventConfig))
            }
            name={`hold_reason_code-${loadId}`}
            data-testid={`hold_reason_code-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      carrier_comments: {
        cellValue: load.carrier_comments,
        cellDisplay: (
          <FormTextField
            formContext={formContext}
            name={`carrier_comments-${loadId}`}
            disabled={disableUpdates}
          />
        ),
      },
      tcc_comments: {
        cellValue: load.tcc_comments,
        cellDisplay: (
          <TCCCommentsControl
            name={`tcc_comments-${loadId}`}
            formContext={formContext}
          />
        ),
      },
      service_id: load.service.code,
      category: `${startCase(load.category)} - ${startCase(load.subcategory)}`,
      update_date: formatDate(load.update_date),
      update_by: load.update_by,
    })
  })

  return mappedLoads
}

const LoadLevelTable = ({
  loads,
  loadCount,
  eventConfig,
  pageNum,
  pageSize,
  userPreferences,
  onExport,
  rules,
  onRefresh,
  onUpdate,
  onPaginationChange,
  onSortChange,
  onColumnDefsChange,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLoad, setSelectedLoad] = useState(null)

  const userColumnDefs = userPreferenceService.laceColumnDefsWithUserPref(
    columnDefs,
    userPreferences?.config_details?.load_tracking?.load_level?.columns,
  )

  const openLoadDetails = (load) => {
    setIsOpen(true)
    setSelectedLoad(load)
  }

  const closeLoadDetails = () => setIsOpen(false)

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: mapDefaultValues(loads),
  })

  const rowData = useMemo(
    () => mapLoads(loads, eventConfig, rules, formContext, openLoadDetails),
    [loads, eventConfig, rules, formContext],
  )

  const {
    formState: { dirtyFields, errors, isDirty },
    handleSubmit,
    reset,
  } = formContext

  const saveLoadUpdates = (values) => {
    const dirtyValues = Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [key, values[key]]),
    )
    const loadUpdates = {}
    forEach(dirtyValues, (value, key) => {
      const [field, loadId] = key.split('-')

      let temp = value
      if (typeof value === 'object') temp = value?.value ?? ''

      setWith(loadUpdates, `${loadId}.${field}`, temp, Object)
    })
    onUpdate(loadUpdates)
  }

  useEffect(() => {
    // reset the form values when loads are updated
    reset(mapDefaultValues(loads))
  }, [loads, reset])

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <LoadDetails
        isOpen={isOpen}
        closeModal={closeLoadDetails}
        load={selectedLoad}
      />

      <NovaTable
        name="Load Tracking"
        showHeader
        enableColumnManager={true}
        columnManagerButtonLocation="right"
        columnDefs={userColumnDefs}
        rowData={rowData}
        rowCount={loadCount}
        pageNum={pageNum}
        pageSize={pageSize}
        onPaginationChange={onPaginationChange}
        onSortChange={(direction, field) => {
          const column = userColumnDefs.find((column) => column.field === field)
          onSortChange(direction, column?.sortBy)
        }}
        onColumnDefsChange={(columns) =>
          onColumnDefsChange('load_level', columns)
        }
        tableActions={
          <Grid.Container
            align="center"
            justify="flex-end"
            spacing="dense"
            noWrap
          >
            <Grid.Item>
              <Tooltip
                content="Save"
                location={!isDirty || !isEmpty(errors) ? 'none' : 'bottom'}
              >
                <Button
                  iconOnly
                  aria-label="Save Loads"
                  data-testid="load-save-btn"
                  className="table-action-button"
                  disabled={!isDirty || !isEmpty(errors)}
                  onClick={handleSubmit(saveLoadUpdates)}
                >
                  <EnterpriseIcon icon={SaveIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Reset" location={!isDirty ? 'none' : 'bottom'}>
                <Button
                  iconOnly
                  aria-label="Reset Loads"
                  className="table-action-button"
                  disabled={!isDirty}
                  onClick={() => reset(mapDefaultValues(loads))}
                >
                  <EnterpriseIcon icon={CancelCircleIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Refresh Loads" location="bottom">
                <Button
                  iconOnly
                  aria-label="Refresh Loads"
                  className="table-action-button"
                  data-testid="load-refresh-btn"
                  onClick={onRefresh}
                >
                  <EnterpriseIcon icon={RefreshIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Export CSV" location="bottom">
                <Button
                  iconOnly
                  aria-label="Export Load CSV"
                  className="table-action-button"
                  data-testid="load-export-btn"
                  onClick={() => onExport(LOAD_TRACKING)}
                >
                  <EnterpriseIcon icon={ExportIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
          </Grid.Container>
        }
        extraBlankRows={9}
        {...restProps}
      />
    </form>
  )
}

export default LoadLevelTable
