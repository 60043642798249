import axios from 'axios'

import apiConfig from '../../../config/apiConfig'
import { get } from 'lodash'
import { format } from 'date-fns'
import { formatDate } from '../../../common/util/dateUtil'

async function getSavedSearches(searchType) {
  try {
    const { data } = await axios.get(
      `${apiConfig.api.edge}/saved_search/${searchType}`,
      {},
    )
    return mapResponseToSavedSearchData(data)
  } catch (e) {
    return get(e, 'response.status') === 404
      ? 'No saved searches'
      : 'Error getting saved searches'
  }
}

function formatDateTimeToFilterValues(dateTime, desiredFormat) {
  return dateTime ? format(new Date(dateTime), desiredFormat) : ''
}

function mapCriteriaToSearchFilters(criteria) {
  const minuteFormat = "yyyy-MM-dd'T'HH:mm"
  const loadPredicate = criteria.predicate_json.load_predicate
  return {
    load_ids: loadPredicate.load_ids,
    trip_ids: loadPredicate.trip_ids,
    scacs: loadPredicate.scacs,
    pro_numbers: loadPredicate.pro_numbers,
    trailer_numbers: loadPredicate.trailer_numbers,
    purchase_order_numbers: loadPredicate.purchase_order_numbers,
    schedule_ids: loadPredicate.schedule_ids,
    origin_ids: loadPredicate.origin_ids?.map((id) => {
      return { label: id, value: id, id: id }
    }),
    destination_ids: loadPredicate.destination_ids?.map((id) => {
      return { label: id, value: id, id: id }
    }),
    service_codes: loadPredicate.service_codes,
    load_statuses: loadPredicate.status_list,
    alert_statuses: loadPredicate.alert_status_list,
    category_list: loadPredicate.category_list,
    hold_reason_code: loadPredicate.hold_reason_codes,
    store_ids: [],
    route_ids: loadPredicate.route_ids,
    critical_departure_start_time: formatDateTimeToFilterValues(
      loadPredicate.critical_departure_time.start,
      minuteFormat,
    ),
    critical_departure_end_time: formatDateTimeToFilterValues(
      loadPredicate.critical_departure_time.end,
      minuteFormat,
    ),
    arrival_start_time: formatDateTimeToFilterValues(
      loadPredicate.arrival_time.start,
      minuteFormat,
    ),
    arrival_end_time: formatDateTimeToFilterValues(
      loadPredicate.arrival_time.end,
      minuteFormat,
    ),

    start_from_date: loadPredicate.load_start_date.start
      ? formatDate(loadPredicate.load_start_date.start).split(',')[0]
      : '',

    start_to_date: loadPredicate.load_start_date.end
      ? formatDate(loadPredicate.load_start_date.end).split(',')[0]
      : '',
  }
}

function mapResponseToSavedSearchData(response) {
  if (!response) return []
  return response.map((searchCriteria) => {
    let loadPredicate = searchCriteria.predicate_json.load_predicate
    return {
      search_name: loadPredicate.search_name,
      criteria_id: searchCriteria.criteria_id,
      default_search: loadPredicate.default_search,
      filters: mapCriteriaToSearchFilters(searchCriteria),
    }
  })
}

async function deleteSavedSearch(id) {
  const { data } = await axios.delete(
    `${apiConfig.api.edge}/saved_search/${id}`,
    {},
  )
  return data
}

async function setDefaultSavedSearch(id) {
  const { data } = await axios.patch(
    `${apiConfig.api.edge}/saved_search/${id}`,
    {},
  )
  return data
}

function mapFilterValuesToCriteria(filters, criteriaType) {
  return {
    predicate_json: {
      load_predicate: {
        search_name: filters.search_name,
        load_ids: filters.load_ids,
        origin_ids: filters.origin_ids?.map((node) => node.value),
        destination_ids: filters.destination_ids?.map((node) => node.value),
        trailer_numbers: filters.trailer_numbers,
        scacs: filters.scacs,
        alert_status_list: filters.alert_statuses,
        trip_ids: filters.trip_ids,
        pro_numbers: filters.pro_numbers,
        purchase_order_numbers: filters.purchase_order_numbers,
        schedule_ids: filters.schedule_ids,
        service_codes: filters.service_codes,
        status_list: filters.load_statuses,
        route_ids: filters.route_ids,
        arrival_time: {
          start: formatDateTimeToCriteriaValues(filters.arrival_start_time),
          end: formatDateTimeToCriteriaValues(filters.arrival_end_time),
        },
        category_list: filters.category_list,
        hold_reason_codes: filters.hold_reason_code,
        critical_departure_time: {
          start: formatDateTimeToCriteriaValues(
            filters.critical_departure_start_time,
          ),
          end: formatDateTimeToCriteriaValues(
            filters.critical_departure_end_time,
          ),
        },
        load_start_date: {
          start: formatDateTimeToCriteriaValues(filters.start_from_date),
          end: formatDateTimeToCriteriaValues(filters.start_to_date),
        },
      },
    },
    criteria_type: criteriaType,
  }
}

function formatDateTimeToCriteriaValues(dateTime) {
  return dateTime ? new Date(dateTime).toISOString() : null
}

async function saveSearch(loadSearchFilters, criteriaType) {
  const saveSearchCriteria = mapFilterValuesToCriteria(
    loadSearchFilters,
    criteriaType,
  )
  const { data } = await axios.post(
    `${apiConfig.api.edge}/saved_search`,
    saveSearchCriteria,
    {},
  )
  return data
}

export default {
  getSavedSearches,
  saveSearch,
  deleteSavedSearch,
  setDefaultSavedSearch,
}
