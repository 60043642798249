import { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useAuth } from '@praxis/component-auth'

import { Grid, Layout, ToastProvider } from '@enterprise-ui/canvas-ui-react'

import { ProgressOverlay, SavedSearchMenu } from '@dlm/common'
import apiConfig from '../../config/apiConfig'
import '../../stylesheets/customStyles.scss'

import dashboardService from './services/dashboardService'
import FMOutstandingLoadSummary from './components/FMOutstandingLoadSummary'
import DashboardSearchFilterDrawer from './components/DashboardSearchFilterDrawer'
import { chain, clone, find, isEmpty, isString, omit, pull } from 'lodash'
import { Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import { EnterpriseIcon, FilterIcon, CaretDownIcon } from '@enterprise-ui/icons'
import savedSearchService from '../LoadTracking/services/savedSearchService'
import SearchFilterChips from '../../common/components/SearchFilterChips'
import {
  DEFAULT_DASHBOARD_SEARCH_FILTERS,
  DEFAULT_MM_DASHBOARD_SEARCH_FILTERS,
} from './constants/dashboardSearchFilterConstants'
import { FIRST_MILE, MIDDLE_MILE } from './constants/dashboardConstants'
import MMDashboard from './components/MMDashboard'

const Dashboard = () => {
  const auth = useAuth()
  const { session } = auth
  const accessToken = session?.accessToken

  // Configure any axios interceptors here
  // Usually we set interceptors globally, but this needs to be inside the component to work with federation
  axios.interceptors.request.use((config) => {
    config.headers['X-API-KEY'] = apiConfig.api.key
    // Usually populated by praxis by default, but doesn't work if accessed from parent mfe app
    config.headers['Authorization'] =
      accessToken && accessToken.includes('Bearer')
        ? accessToken
        : `Bearer ${accessToken}`
    return config
  })

  const makeToast = ToastProvider.useToaster()
  const [fmLoadSummary, setFMLoadSummary] = useState(null)
  const [mmLoadSummary, setMMLoadSummary] = useState(null)
  const [inProgress, setInProgress] = useState(false)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [savedSearchData, setSavedSearchData] = useState('')
  const [dashboardView, setDashboardView] = useState(FIRST_MILE)
  const [summarySearchFilters, setSummarySearchFilters] = useState({
    selected_filters: [],
    ...DEFAULT_DASHBOARD_SEARCH_FILTERS,
  })

  const formatStateFilters = (rawFilters) => {
    let filters = {}
    rawFilters?.forEach((filter) => {
      filters[filter?.filter_name] = filter?.filter_values
    })
    return {
      ...summarySearchFilters,
      ...filters,
    }
  }

  const setDefaultFirstMileSearchFilters = () => {
    setSummarySearchFilters({
      selected_filters: [],
      ...DEFAULT_DASHBOARD_SEARCH_FILTERS,
    })
  }

  const setDefaultMiddleMileSearchFilters = () => {
    setSummarySearchFilters(DEFAULT_MM_DASHBOARD_SEARCH_FILTERS())
  }

  const onSearchFilterChange = useCallback((newFilters) => {
    const updatedSelectedFilters = chain(newFilters)
      .pick(Object.keys(DEFAULT_DASHBOARD_SEARCH_FILTERS))
      .omitBy(isEmpty)
      .keys()
      .value()

    // Need to re-instate pagination and sort properties as filter drawer strips them
    setSummarySearchFilters({
      ...newFilters,
      selected_filters: updatedSelectedFilters,
    })
  }, [])

  const onSavedSearchLoad = useCallback(
    (newFilters) => {
      if (dashboardView === MIDDLE_MILE) {
        onSearchFilterChange({
          ...newFilters,
          arrival_start_time: summarySearchFilters.arrival_start_time,
          arrival_end_time: summarySearchFilters.arrival_end_time,
        })
      } else onSearchFilterChange(newFilters)
    },
    [dashboardView, onSearchFilterChange, summarySearchFilters],
  )

  const savedSearchRef = useRef(null)
  const getSavedSearches = useCallback(() => {
    setSavedSearchData([])
    savedSearchService
      .getSavedSearches(
        dashboardView === FIRST_MILE
          ? 'FM_DASHBOARD_SAVED_SEARCH'
          : 'MM_DASHBOARD_SAVED_SEARCH',
      )
      .then((mappedResponse) => {
        savedSearchRef.current = null
        setSavedSearchData(mappedResponse)
      })
  }, [dashboardView])

  const mapToSavedSearchFilters = (filters) => {
    const mappedFilters = ['origin_ids', 'destination_ids']
    mappedFilters.forEach((filter) => {
      filters[filter] = filters[filter]?.flatMap((filterValue) => {
        return {
          value: filterValue,
          label: filterValue,
          id: filterValue,
        }
      })
    })
    if (dashboardView === MIDDLE_MILE) {
      return omit(filters, ['arrival_start_time', 'arrival_end_time'])
    }
    return filters
  }

  const onSaveSearch = (newFilters) => {
    savedSearchService
      .saveSearch(
        mapToSavedSearchFilters(newFilters),
        dashboardView === FIRST_MILE
          ? 'FM_DASHBOARD_SAVED_SEARCH'
          : 'MM_DASHBOARD_SAVED_SEARCH',
      )
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully saved search',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error saving search',
        })
      })
  }

  const deleteSavedSearch = (criteriaId) => {
    savedSearchService
      .deleteSavedSearch(criteriaId)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully deleted search',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error deleting search',
        })
      })
  }

  const markDefault = (criteriaId) => {
    savedSearchService
      .setDefaultSavedSearch(criteriaId)
      .then(() => {
        getSavedSearches()
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'Successfully marked default',
        })
      })
      .catch((error) => {
        console.error(error)
        makeToast({
          type: 'error',
          heading: 'Server Error',
          message: 'Error marking default',
        })
      })
  }

  const getLoadSummary = useCallback(() => {
    if (savedSearchRef.current) {
      setInProgress(true)
      switch (dashboardView) {
        case FIRST_MILE:
          dashboardService
            .getFMLoadSummary(omit(summarySearchFilters, 'selected_filters'))
            .then((resp) => {
              setFMLoadSummary(resp)
            })
            .catch((e) => {
              makeToast({
                type: 'error',
                heading: 'Server Error',
                message: `Error fetching dashboard data: ${e.response?.data?.error_message || 'Unknown Error'}`,
              })
            })
            .finally(() => {
              // TODO - Remove timeout after https://jira.target.com/browse/EUI-1740 is implemented
              setTimeout(() => setInProgress(false), 500)
            })
          break
        case MIDDLE_MILE:
          dashboardService
            .getMMLoadSummary(omit(summarySearchFilters, 'selected_filters'))
            .then((resp) => {
              setMMLoadSummary(resp)
            })
            .catch((e) => {
              makeToast({
                type: 'error',
                heading: 'Server Error',
                message: `Error fetching dashboard data: ${e.response?.data?.error_message || 'Unknown Error'}`,
              })
            })
            .finally(() => {
              setTimeout(() => setInProgress(false), 500)
            })
          break
        default:
          break
      }
    }
  }, [summarySearchFilters, makeToast, dashboardView])

  useEffect(() => {
    // Fetch saved searches
    getSavedSearches()
  }, [getSavedSearches])

  useEffect(() => {
    getLoadSummary()
  }, [getLoadSummary])

  useEffect(() => {
    if (!savedSearchRef.current && !isEmpty(savedSearchData)) {
      // Saved searches loaded. We can now fetch default saved search or default search criteria

      const defaultSavedSearch =
        !isString(savedSearchData) &&
        find(savedSearchData, { default_search: true })

      if (defaultSavedSearch) {
        if (dashboardView === FIRST_MILE) {
          onSearchFilterChange(defaultSavedSearch.filters)
        } else {
          onSearchFilterChange({
            ...defaultSavedSearch.filters,
            arrival_start_time: summarySearchFilters.arrival_start_time,
            arrival_end_time: summarySearchFilters.arrival_end_time,
          })
        }
      } else {
        setSummarySearchFilters(clone(summarySearchFilters))
      }
    }

    if (!isEmpty(savedSearchData)) {
      savedSearchRef.current = savedSearchData
    }
  }, [
    summarySearchFilters,
    savedSearchData,
    onSearchFilterChange,
    dashboardView,
  ])

  const onSearchFilterDelete = (filter) => {
    setSummarySearchFilters({
      ...summarySearchFilters,
      selected_filters: pull(summarySearchFilters.selected_filters, filter),
      [filter]: DEFAULT_DASHBOARD_SEARCH_FILTERS[filter],
    })
  }

  const onSearchFilterReset = () => {
    if (dashboardView === MIDDLE_MILE) {
      setSummarySearchFilters({
        selected_filters: [],
        ...DEFAULT_MM_DASHBOARD_SEARCH_FILTERS(),
      })
    } else {
      setSummarySearchFilters({
        selected_filters: [],
        ...DEFAULT_DASHBOARD_SEARCH_FILTERS,
      })
    }
  }

  const onFMDashboardSelected = () => {
    setDefaultFirstMileSearchFilters()
    setDashboardView(FIRST_MILE)
    setSavedSearchData('')
    savedSearchRef.current = null
  }

  const onMMDashboardSelected = () => {
    setDefaultMiddleMileSearchFilters()
    setDashboardView(MIDDLE_MILE)
    setSavedSearchData('')
    savedSearchRef.current = null
  }

  return (
    <>
      <ProgressOverlay inProgress={inProgress} />
      <DashboardSearchFilterDrawer
        isVisible={filtersVisible}
        onRequestClose={() => {
          setFiltersVisible(false)
        }}
        onChange={onSearchFilterChange}
        onSaveSearch={onSaveSearch}
        savedSearches={savedSearchData}
        givenValues={summarySearchFilters}
        defaultValues={DEFAULT_DASHBOARD_SEARCH_FILTERS}
      />
      <Layout.Body data-testid="dashboard" includeRail>
        <Grid.Container justify="space-between" style={{ marginBottom: 10 }}>
          <Grid.Item>
            <Dropdown>
              <Button aria-label="Switch Between Dashboards Menu">
                <EnterpriseIcon
                  icon={CaretDownIcon}
                  size="sm"
                  style={{ marginRight: 5 }}
                />
                Dashboards
              </Button>
              <Dropdown.Menu>
                <Dropdown.MenuItem
                  onClick={() => {
                    onFMDashboardSelected()
                  }}
                  aria-label="Switch to First Mile Dashboard Button"
                >
                  First Mile Dashboard
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onClick={() => {
                    onMMDashboardSelected()
                  }}
                  aria-label="Switch to Middle Mile Dashboard Button"
                >
                  Middle Mile Dashboard
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
          <Grid.Item>
            <Grid.Container>
              <SavedSearchMenu
                mappedSavedSearchData={savedSearchData}
                onLoadSearch={onSavedSearchLoad}
                onToggleDefaultSearch={markDefault}
                onDelete={deleteSavedSearch}
              />
              <Grid.Item>
                <Button
                  onClick={() => {
                    setFiltersVisible(true)
                  }}
                  aria-label="Show Search Filters"
                  data-testid="filters-button"
                >
                  <EnterpriseIcon
                    icon={FilterIcon}
                    size="sm"
                    style={{ marginRight: 5 }}
                  />
                  Show Filters
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Container direction="column">
          <Grid.Item xs={12}>
            {dashboardView === MIDDLE_MILE && (
              <MMDashboard
                loadSummary={mmLoadSummary}
                subheader={
                  <SearchFilterChips
                    searchFilters={summarySearchFilters}
                    onDelete={onSearchFilterDelete}
                    onReset={onSearchFilterReset}
                  />
                }
                formatStateFilters={formatStateFilters}
              />
            )}
            {dashboardView === FIRST_MILE && (
              <FMOutstandingLoadSummary
                loadSummary={fmLoadSummary}
                formatStateFilters={formatStateFilters}
                subHeader={
                  <SearchFilterChips
                    searchFilters={summarySearchFilters}
                    onDelete={onSearchFilterDelete}
                    onReset={onSearchFilterReset}
                  />
                }
              />
            )}
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    </>
  )
}

export default Dashboard
