import axios from 'axios'
import { sortBy } from 'lodash'

import apiConfig from '../../../config/apiConfig'

export function getUserPreferences() {
  return axios.get(`${apiConfig.api.edge}/user-configs`)
}

export function saveUserPreferences(request) {
  return axios.post(`${apiConfig.api.edge}/user-configs`, request)
}

const laceColumnDefsWithUserPref = (columnDefs, userColumnPrefs) => {
  let userColumnDefs = columnDefs

  if (userColumnPrefs) {
    const updatedColumns = userColumnDefs.map((columnDef) => {
      const userColumn =
        userColumnPrefs.find((column) => column.name === columnDef.field) ?? {}

      return {
        ...columnDef,
        position: userColumn.position,
        pinned: userColumn.is_pinned ?? false,
        isVisible: userColumn.is_visible ?? true,
      }
    })

    userColumnDefs = sortBy(updatedColumns, ['position'])
  }

  return userColumnDefs
}

export default {
  getUserPreferences,
  saveUserPreferences,
  laceColumnDefsWithUserPref,
}
