import { useEffect, useMemo, useState } from 'react'
import { find, forEach, includes, isEmpty, setWith, sortBy } from 'lodash'
import { useForm } from 'react-hook-form'

import {
  Anchor,
  Button,
  Chip,
  Grid,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'

import EnterpriseIcon, {
  CancelCircleIcon,
  ExportIcon,
  RefreshIcon,
  SaveIcon,
} from '@enterprise-ui/icons'

import {
  FormAutocomplete,
  FormDateTimePicker,
  FormTextField,
  NovaTable,
} from '@dlm/common'

import LoadDetails from './LoadDetails'

import {
  convertToLocationZone,
  formatDate,
  formatDateTime,
} from '../../../common/util/dateUtil'
import { startCase } from '../../../common/util/stringUtil'
import { getAlertStatusColor } from '../util/alertStatusUtil'

import { STOP_TRACKING } from '../constants/reportConstants'
import TCCCommentsControl from './TCCCommentsControl'
import { findMultiPickRuleValues } from '../util/rulesUtil'
import { autoCompleteValidationRule } from '../util/validationUtil'
import {
  formatDefectCategories,
  formatDefectReason,
} from '../../../common/util/eventConfigUtil'
import userPreferenceService from '../services/userPreferenceService'

const columnDefs = [
  {
    headerName: 'Load ID',
    field: 'load_id',
    width: 130,
    isRowHeader: true,
    sortable: true,
    // defaultSort: 'dsc',
  },
  {
    headerName: 'Trip ID',
    field: 'trip_id',
    width: 100,
    sortable: true,
  },
  {
    headerName: 'Route ID',
    field: 'route_id',
    width: 120,
    sortable: true,
  },
  {
    headerName: 'Pro #',
    field: 'pro_number',
    width: 125,
    sortable: true,
  },
  {
    headerName: 'SCAC',
    field: 'current_scac',
    width: 100,
    sortable: true,
  },
  {
    headerName: 'Unload Type',
    field: 'delivery_type',
    sortBy: 'delivery_type',
    width: 150,
    sortable: true,
  },
  // TODO: sort by status doesn't seem to be actually sorting
  {
    headerName: 'Status',
    field: 'load_status',
    width: 150,
    sortable: true,
  },
  {
    headerName: 'Driver Status',
    field: 'driver_status',
    width: 150,
    sortable: true,
  },
  {
    headerName: 'Stop',
    field: 'stop_number',
    width: 100,
    sortable: false,
  },
  {
    headerName: 'Type',
    field: 'stop_type',
    width: 100,
    sortable: false,
  },
  {
    headerName: 'Location',
    field: 'location',
    width: 300,
    sortable: false,
  },
  {
    headerName: 'Alert Status',
    field: 'alert_status',
    width: 250,
    sortable: false,
  },
  {
    headerName: 'Expected Pickup/Drop',
    width: 200,
    field: 'expected_pick_drop',
    sortable: false,
  },
  {
    headerName: 'EDI 214 Actual Pickup/Drop',
    width: 200,
    field: 'edi_214_actual_pick_drop',
    sortable: false,
  },
  {
    headerName: 'Estimated Pickup/Drop',
    width: 250,
    field: 'estimated_pick_or_drop_time',
    sortable: false,
  },
  {
    headerName: 'Actual Pickup/Drop',
    width: 250,
    field: 'actual_pick_or_drop_time',
    sortable: false,
  },
  {
    headerName: 'Defect Reason',
    field: 'defect_reason',
    width: 250,
    sortable: false,
  },
  {
    headerName: 'Defect Category',
    field: 'defect_category',
    width: 250,
    sortable: false,
  },
  {
    headerName: 'Carrier Comments',
    width: 300,
    field: 'carrier_comments',
    sortable: false,
  },
  {
    headerName: 'TCC Comments',
    width: 300,
    field: 'tcc_comments',
    sortable: false,
  },
  {
    headerName: 'Trailer #',
    field: 'trailer_number',
    width: 120,
    sortable: false,
  },
  {
    headerName: 'Category',
    width: 350,
    field: 'category',
    sortable: false,
  },
  {
    headerName: 'Last Updated',
    field: 'update_date',
    width: 200,
    sortable: false,
  },
  {
    headerName: 'Updated By',
    field: 'update_by',
    width: 300,
    sortable: false,
  },
]

const mapDefaultValues = (loads) => {
  const defaultValues = {}

  // No need to map load values, they aren't to be editable in stop view
  loads.forEach((load) => {
    const loadId = load.load_id

    load.stops.forEach((stop) => {
      // Temporary fix for breaking defect reason dto change
      const defectReasonCode = stop.defect_reason?.code
      const defectReasonDescription = stop.defect_reason?.description

      const defectReason =
        defectReasonCode && defectReasonDescription
          ? `${defectReasonCode}-${defectReasonDescription}`
          : ''

      defaultValues[`carrier_comments-${loadId}-${stop.sequence_number}`] =
        stop.carrier_comments || ''
      defaultValues[`tcc_comments-${loadId}-${stop.sequence_number}`] =
        stop.tcc_comments || ''
      defaultValues[
        `estimated_pick_or_drop_time-${loadId}-${stop.sequence_number}`
      ] =
        formatDateTime(
          stop.carrier_estimated_pickup_time,
          stop.time_zone_offset_from_utc,
        ) ||
        formatDateTime(
          stop.carrier_estimated_delivery_time,
          stop.time_zone_offset_from_utc,
        ) ||
        ''
      defaultValues[
        `actual_pick_or_drop_time-${loadId}-${stop.sequence_number}`
      ] =
        formatDateTime(
          stop.actual_pickup_time,
          stop.time_zone_offset_from_utc,
        ) ||
        formatDateTime(
          stop.actual_delivery_time,
          stop.time_zone_offset_from_utc,
        ) ||
        ''
      defaultValues[`defect_reason-${loadId}-${stop.sequence_number}`] = {
        id: defectReason || '',
        label: defectReason || '',
        value: defectReason || '',
      }
      defaultValues[`defect_category-${loadId}-${stop.sequence_number}`] = {
        id: stop.defect_category || '',
        label: stop.defect_category || '',
        value: stop.defect_category || '',
      }
    })
  })

  return defaultValues
}

const mapStops = (loads, eventConfig, rules, formContext, openLoadDetails) => {
  const mappedStops = []
  const stopLevelUpdateCategories = findMultiPickRuleValues(rules)
  loads.forEach((load) => {
    const loadId = load.load_id
    const mappedStopsForLoad = []
    const disableUpdates = !includes(
      stopLevelUpdateCategories,
      load.subcategory,
    )

    // Populate common load details
    const mappedLoadData = {
      load_id: {
        cellValue: load.load_id,
        cellDisplay: (
          <Anchor
            aria-label={`Load Details for ${load.load_id}`}
            as="span"
            href="#"
            onClick={() => openLoadDetails(load)}
          >
            {load.load_id}
          </Anchor>
        ),
      },
      trip_id: load.trip_id,
      route_id: load.route_id,
      pro_number: load.pro_number,
      trailer_number: load.trailer_number,
      current_scac: load.carrier?.scac,
      delivery_type: load.delivery_type,
      load_status: {
        cellValue: load.load_status,
        cellDisplay: startCase(load.load_status),
      },
      driver_status: {
        cellValue: load.driver_status,
        cellDisplay: startCase(load.driver_status),
      },
      category: `${startCase(load.category)} - ${startCase(load.subcategory)}`,
      update_date: formatDate(load.update_date),
      update_by: load.update_by,
    }

    // Stop details
    sortBy(load.stops, 'sequence_number').forEach((stop, i) => {
      const sequenceNumber = stop.sequence_number

      // Temporary fix for breaking defect reason dto change
      const defectReasonCode = stop.defect_reason?.code
      const defectReasonDescription = stop.defect_reason?.description

      const defectReason =
        defectReasonCode && defectReasonDescription
          ? `${defectReasonCode}-${defectReasonDescription}`
          : ''

      const mappedStopData = {
        stop_number: stop.sequence_number,
        stop_type: startCase(stop.type),
        location: {
          cellValue: stop.location_code,
          cellDisplay: (
            <>
              {stop.location_code} - {startCase(stop.location_name)}
            </>
          ),
        },
        alert_status: {
          cellValue: stop.alert_status,
          cellDisplay: (
            <>
              {stop.alert_status && (
                <Chip
                  size="dense"
                  color={getAlertStatusColor(stop.alert_status)}
                >
                  {stop.alert_status}
                </Chip>
              )}
            </>
          ),
        },
        defect_reason: {
          cellValue: defectReason,
          cellDisplay: (
            <FormAutocomplete
              formContext={formContext}
              options={formatDefectReason(eventConfig)}
              optionHeight="expanded"
              rules={
                !disableUpdates &&
                autoCompleteValidationRule(formatDefectReason(eventConfig))
              }
              name={`defect_reason-${loadId}-${sequenceNumber}`}
              data-testid={`defect_reason-${loadId}-${sequenceNumber}`}
              disabled={disableUpdates}
            />
          ),
        },
        defect_category: {
          cellValue: stop.defect_category,
          cellDisplay: (
            <FormAutocomplete
              formContext={formContext}
              options={formatDefectCategories(eventConfig)}
              optionHeight="expanded"
              rules={
                !disableUpdates &&
                autoCompleteValidationRule(formatDefectCategories(eventConfig))
              }
              name={`defect_category-${loadId}-${sequenceNumber}`}
              data-testid={`defect_category-${loadId}-${sequenceNumber}`}
              disabled={disableUpdates}
            />
          ),
        },
        estimated_pick_or_drop_time: {
          cellValue:
            stop.carrier_estimated_pickup_time ||
            stop.carrier_estimated_delivery_time,
          cellDisplay: (
            <FormDateTimePicker
              formContext={formContext}
              type={'datetime-local'}
              name={`estimated_pick_or_drop_time-${loadId}-${sequenceNumber}`}
              data-testid={`estimated_pick_or_drop_time-${loadId}-${sequenceNumber}`}
              disabled={disableUpdates}
            />
          ),
        },
        actual_pick_or_drop_time: {
          cellValue: stop.actual_pickup_time || stop.actual_delivery_time,
          cellDisplay: (
            <FormDateTimePicker
              formContext={formContext}
              type={'datetime-local'}
              name={`actual_pick_or_drop_time-${loadId}-${sequenceNumber}`}
              disabled={disableUpdates}
            />
          ),
        },
        carrier_comments: {
          cellValue: stop.carrier_comments,
          cellDisplay: (
            <FormTextField
              formContext={formContext}
              name={`carrier_comments-${loadId}-${sequenceNumber}`}
              disabled={disableUpdates}
            />
          ),
        },
        tcc_comments: {
          cellValue: stop.tcc_comments,
          cellDisplay: (
            <TCCCommentsControl
              name={`tcc_comments-${loadId}-${sequenceNumber}`}
              formContext={formContext}
            />
          ),
        },
        expected_pick_drop: convertToLocationZone(
          stop.type.toLowerCase() === 'pick' && stop.sequence_number === 1
            ? load.critical_departure || load.expected_pickup_date
            : stop.planned_arrival_time,
          stop.time_zone_offset_from_utc,
        ),
        edi_214_actual_pick_drop: convertToLocationZone(
          stop.edi214_actual_time,
          stop.time_zone_offset_from_utc,
        ),
      }

      // Load data displayed only for the first stop in the sequence
      mappedStopsForLoad.push(
        Object.assign(
          {
            id: `${load.load_id}-${stop.sequence_number}`,
            ...mappedStopData,
          },
          i === 0 ? mappedLoadData : {},
        ),
      )
    })

    // Extra precaution. Not really needed, but doesn't hurt. Will display load data at least if no stops present
    if (isEmpty(mappedStopsForLoad)) {
      mappedStopsForLoad.push({
        id: `${load.load_id}-1`,
        ...mappedLoadData,
      })
    }
    mappedStops.push(...mappedStopsForLoad)
  })

  return mappedStops
}

const StopLevelTable = ({
  loads,
  loadCount,
  pageNum,
  pageSize,
  userPreferences,
  onExport,
  onRefresh,
  onUpdate,
  onPaginationChange,
  onSortChange,
  onColumnDefsChange,
  eventConfig,
  rules,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLoad, setSelectedLoad] = useState(null)

  const userColumnDefs = userPreferenceService.laceColumnDefsWithUserPref(
    columnDefs,
    userPreferences?.config_details?.load_tracking?.stop_level?.columns,
  )

  const openLoadDetails = (load) => {
    setIsOpen(true)
    setSelectedLoad(load)
  }

  const closeLoadDetails = () => setIsOpen(false)

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: mapDefaultValues(loads),
  })

  const rowData = useMemo(
    () => mapStops(loads, eventConfig, rules, formContext, openLoadDetails),
    [loads, eventConfig, rules, formContext],
  )

  const {
    formState: { dirtyFields, errors, isDirty },
    handleSubmit,
    reset,
  } = formContext

  useEffect(() => {
    // reset the form values when loads are updated
    reset(mapDefaultValues(loads))
  }, [loads, reset])

  const saveStopUpdates = (values) => {
    // Find the updated values (We only send updates to the api)
    const dirtyValues = Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [key, values[key]]),
    )
    // Map to service contract
    // We are reusing the mapper from Mercury (with some cleaning up)
    // We just need this function to map it to its input contract
    const stopUpdates = {}
    forEach(dirtyValues, (value, key) => {
      const [field, loadId, sequenceNumber] = key.split('-')

      const stopType = find(
        find(loads, (load) => load.load_id === loadId).stops,
        (stop) => stop.sequence_number === Number(sequenceNumber),
      ).type

      // Can use a complicated reduce, but lodash.setWith is a lot cleaner, albeit possibly less efficient
      setWith(
        stopUpdates,
        `${loadId}.stops.${sequenceNumber}.stop_number`,
        sequenceNumber,
        Object,
      )
      setWith(
        stopUpdates,
        `${loadId}.stops.${sequenceNumber}.stop_type`,
        stopType,
        Object,
      )

      let temp = value
      if (typeof value === 'object') temp = value?.value ?? ''

      setWith(
        stopUpdates,
        `${loadId}.stops.${sequenceNumber}.${field}`,
        temp,
        Object,
      )

      // TODO - Set more stop fields here as and when needed (And in the service mapping)
    })
    onUpdate(stopUpdates)
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <LoadDetails
        isOpen={isOpen}
        closeModal={closeLoadDetails}
        load={selectedLoad}
      />

      <NovaTable
        name="Stop Tracking"
        showHeader
        enableColumnManager
        columnManagerButtonLocation="right"
        columnDefs={userColumnDefs}
        rowData={rowData}
        rowCount={loadCount}
        pageNum={pageNum}
        pageSize={pageSize}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        onColumnDefsChange={(columns) =>
          onColumnDefsChange('stop_level', columns)
        }
        tableActions={
          <Grid.Container
            align="center"
            justify="flex-end"
            spacing="dense"
            noWrap
          >
            <Grid.Item>
              <Tooltip
                content="Save"
                location={!isDirty || !isEmpty(errors) ? 'none' : 'bottom'}
              >
                <Button
                  iconOnly
                  aria-label="Save Stops"
                  className="table-action-button"
                  data-testid="stop-save-btn"
                  disabled={!isDirty || !isEmpty(errors)}
                  onClick={handleSubmit(saveStopUpdates)}
                >
                  <EnterpriseIcon icon={SaveIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Reset" location={!isDirty ? 'none' : 'bottom'}>
                <Button
                  iconOnly
                  aria-label="Reset Stops"
                  className="table-action-button"
                  disabled={!isDirty}
                  onClick={() => reset(mapDefaultValues(loads))}
                >
                  <EnterpriseIcon icon={CancelCircleIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Refresh Loads" location="bottom">
                <Button
                  iconOnly
                  aria-label="Refresh Stops"
                  className="table-action-button"
                  data-testid="stop-refresh-btn"
                  onClick={onRefresh}
                >
                  <EnterpriseIcon icon={RefreshIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Export CSV" location="bottom">
                <Button
                  iconOnly
                  aria-label="Export Stop CSV"
                  className="table-action-button"
                  data-testid="stop-export-btn"
                  onClick={() => onExport(STOP_TRACKING)}
                >
                  <EnterpriseIcon icon={ExportIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
          </Grid.Container>
        }
        extraBlankRows={9}
        {...restProps}
      />
    </form>
  )
}

export default StopLevelTable
